import * as React from 'react';

import { useTheme } from '@emotion/react';
import { Option, optionClasses } from '@mui/base/Option';
import { OptionGroup } from '@mui/base/OptionGroup';
import { Popper } from '@mui/base/Popper';
import { Select, selectClasses } from '@mui/base/Select';
import { Grow } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 100px;
  width: calc(100% + 15px);
  padding: 12px;
  border-radius: 5px;
  text-align: left;
  line-height: 1.5;
  font-size: 13px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]} !important;
  color: ${theme.palette.text.primary};

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  cursor: pointer;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &.${selectClasses.focusVisible} {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  &.${selectClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme, ...props }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  max-height: 400px;
  width: calc(100% + 15px);
  min-width: ${props.minWidth || '100px'};
  border-radius: 5px;
  overflow: auto;
  outline: 0px;
  font-size: 13px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.text.primary};
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);

const StyledOption = styled(Option)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
);

const StyledGroupRoot = styled('li')`
  list-style: none;
`;

const StyledGroupHeader = styled('span')`
  display: block;
  padding: 15px 0 5px 10px;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${grey[600]};
`;

const StyledGroupOptions = styled('ul')`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding-left: 20px;
  }
`;

const StyledPopper = styled(Popper)(
  ({ theme, ...props }) => `
  z-index: 1000000 !important;
  transition: scale .2s ease-in-out
  transform: ${props.open ? 'scale(1)' : 'scale(0)'};
`,
);

const AnimatedPoper = ({ children, ...props }) => {
  return (
    <StyledPopper {...props} transition>
      {({ TransitionProps }) => {
        return (
          <Grow {...TransitionProps} timeout={350}>
            {children}
          </Grow>
        );
      }}
    </StyledPopper>
  );
};

function CustomSelect(props) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: AnimatedPoper,
    ...props.components,
  };

  return <Select {...props} slots={slots} slotProps={props.componentsProps} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
};

const CustomOptionGroup = React.forwardRef(function CustomOptionGroup(props, ref) {
  const components = {
    root: StyledGroupRoot,
    label: StyledGroupHeader,
    list: StyledGroupOptions,
    ...props.components,
  };

  return <OptionGroup {...props} ref={ref} slots={components} />;
});

CustomOptionGroup.propTypes = {
  /**
   * The components used for each slot inside the OptionGroupUnstyled.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    label: PropTypes.elementType,
    list: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

function renderValue(option, groupedOptions, theme) {
  if (option == null) {
    return <span>Select a programme...</span>;
  }

  const fullOption = groupedOptions.find((group) => group.options?.some((programme) => programme.id === option.value));

  return (
    <span style={{ color: theme.palette.text.secondary }}>
      Programme:{' '}
      <span style={{ color: theme.palette.text.primary }}>
        {fullOption?.name} / {option.label}
      </span>
    </span>
  );
}

export const CustomGroupingSelect = ({ value, onChange, groupedOptions, componentProps }) => {
  const theme = useTheme();

  return (
    <CustomSelect
      onChange={onChange}
      value={value}
      renderValue={(option) => renderValue(option, groupedOptions, theme)}
      componentsProps={{ listbox: { minWidth: '250px' }, ...componentProps }}>
      {groupedOptions.map((group) => (
        <CustomOptionGroup key={group.name} label={group.name}>
          {group.options.map((option) => (
            <StyledOption key={option.id} value={option.id}>
              {option.name}
            </StyledOption>
          ))}
        </CustomOptionGroup>
      ))}
    </CustomSelect>
  );
};

export const BigAgileSelect = ({ value, onChange, options, renderValue, componentProps }) => {
  return (
    <CustomSelect onChange={onChange} value={value} renderValue={renderValue} componentsProps={componentProps}>
      {options.map((option) => (
        <StyledOption key={option.id} value={option.id}>
          {option.name}
        </StyledOption>
      ))}
    </CustomSelect>
  );
};
