import React from 'react';

import { useTheme } from '@emotion/react';

import { selectedProgrammeVar, selectedValueStreamVar } from '../../../reactiveVariables';
import { BigAgileSelect } from '../Select/CustomSelect';

const ValueStreamSelector = ({ valueStreams, value, handleChange, allOption }) => {
  const theme = useTheme();

  if (allOption) {
    valueStreams = [
      {
        id: null,
        name: 'All Value Streams',
      },
      ...valueStreams,
    ];
  }

  if (!value && valueStreams[0]) {
    selectedProgrammeVar(valueStreams[0]?.programmes?.[0]?.id);
    selectedValueStreamVar(valueStreams[0]?.id);
    window.localStorage.setItem('selectedProgramme', valueStreams[0]?.programmes?.[0]?.id);
    window.localStorage.setItem('selectedValueStream', valueStreams[0]?.id);
  }

  const renderValue = (option) => (
    <span style={{ color: theme.palette.text.secondary }}>
      VS: <span style={{ color: theme.palette.text.primary }}>{option?.label || 'All Value Streams'}</span>
    </span>
  );

  return (
    <BigAgileSelect
      options={valueStreams}
      value={value}
      onChange={(event, newValue) => {
        if (newValue === null) {
          handleChange(event, null);
        } else {
          handleChange(event, newValue);
        }
      }}
      renderValue={renderValue}
    />
  );
};

export default ValueStreamSelector;
