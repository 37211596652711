import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Text, Dialog, Paper, Divider, Group } from '@mantine/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Waypoint } from 'react-waypoint';

import SvgDate from '../../../assets/images/Date';
import SvgFeatureIcon from '../../../assets/images/FeatureIcon';
import { styles } from '../../../components/Dashboard/FeatureBoard/styles';
import { openDrawer, stickyHeaderVar } from '../../../reactiveVariables';
import { color, lightColors } from '../../../shared/styles/color';
import { toDisplayDate } from '../../../utils/helpers';
import LinearWithValueLabel from '../../ValueStream/components/OKRTable/LinearWithValueLabel';
import { FeatureCard } from '../../ValueStream/Kanban/styles';
import { getInitiativeBgColor, countStatuses } from '../../ValueStream/utils';

const useStyles = makeStyles(styles);

const Breakdown = ({ allInitiatives, valueStreams, portfolioStatesConfig }) => {
  const theme = useTheme();
  const stickyHeader = useReactiveVar(stickyHeaderVar);

  const classes = useStyles({ stickyHeader: stickyHeader });

  const [showAllInitiatives, setShowAllInitiatives] = useState({});
  const [hoveredProgrammes, setHoveredProgrammes] = useState(null);
  const [hoveredInitiative, setHoveredInitiative] = useState(null);

  if (!valueStreams.some((stream) => stream.name === 'Portfolio')) {
    valueStreams.unshift({
      name: 'Portfolio',
      id: 0,
    });
  }

  useEffect(() => () => stickyHeaderVar(false), []);

  const handleValueStreamClick = (valueStream) => {
    openDrawer({ ...valueStream }, 'valueStream');
  };

  const handleInitiativeClick = (initiative, valueStream) => {
    openDrawer({ ...initiative, valueStream }, 'initiative');
  };

  const toggleShowAllFeaturesForStage = (initiativeId, stageValue) => {
    setShowAllInitiatives((prev) => ({
      ...prev,
      [initiativeId]: {
        ...prev[initiativeId],
        [stageValue]: !prev?.[initiativeId]?.[stageValue],
      },
    }));
  };

  const renderValueStreamDetails = (valueStream, portfolioStatesConfig) => {
    const plannedInitiative = portfolioStatesConfig.map((portfolioState) => {
      // Function to check if the value stream ID is present in the portfolioValueStreams array
      const isValueStreamInPortfolioStreams = (portfolioValueStreams, valueStreamId) => {
        return portfolioValueStreams.some((vs) => vs.id === valueStreamId);
      };

      // Function to get portfolioStateName from initiative.initiative_state
      const getPortfolioStateNameFromInitiativeState = (initiativeState, portfolioStatesConfig) => {
        for (const portfolioState of portfolioStatesConfig) {
          const state = portfolioState.states.find((state) => state.name === initiativeState);
          if (state) {
            return portfolioState.name;
          }
        }
        return 'Unmapped status'; // Default value if no match is found
      };

      // Filter initiatives based on value stream ID or portfolioValueStreams
      const filteredInitiatives = allInitiatives.filter((initiative) => {
        if (initiative.isPortfolioState) {
          // Include initiatives with isPortfolioState true in the Portfolio value stream (ID 0)
          return (
            valueStream.id === 0 || isValueStreamInPortfolioStreams(initiative.portfolioValueStreams, valueStream.id)
          );
        } else {
          // For other initiatives, check valueStream directly
          return initiative.valueStream === valueStream.id;
        }
      });

      // Further filter based on portfolioState if needed
      const initiativesForState = filteredInitiatives.filter((initiative) => {
        const portfolioStateName = initiative.isPortfolioState
          ? initiative.portfolio_state === null || initiative.portfolio_state === 'Unknown state'
            ? 'Unmapped status'
            : initiative.portfolio_state
          : getPortfolioStateNameFromInitiativeState(initiative.initiative_state, portfolioStatesConfig);

        return portfolioStateName === portfolioState.name;
      });

      // Determine whether to show all initiatives or just a subset
      const displayedInitiatives = showAllInitiatives[valueStream.id]?.[portfolioState.name]
        ? initiativesForState
        : initiativesForState.slice(0, 3);

      return (
        <TableCell
          key={`${portfolioState.name}+${portfolioState.rank}`}
          style={{
            minWidth: '220px',
            maxWidth: '220px',
            verticalAlign: 'top',
            borderRight: `1px solid ${theme.palette.color.paleGrey2}`,
            backgroundColor: theme.palette.mode === 'dark' ? 'black' : lightColors.background,
          }}>
          {displayedInitiatives.map((initiative, index) => {
            const ragColor = getInitiativeBgColor(initiative, theme);
            const progress = initiative.features?.aggregate?.avg?.percentageDone || 0;
            const statusCount = countStatuses(initiative);

            return (
              <>
                <FeatureCard
                  style={{
                    boxShadow: initiative.isPortfolioState ? `0px 0px 7px -1px ${color.companyColor}` : '',
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    borderLeft: `4px solid ${ragColor}`,
                  }}
                  key={`${initiative.id}+${index}`}
                  onClick={() => handleInitiativeClick(initiative, valueStream)}
                  onMouseEnter={() => setHoveredInitiative(initiative.id)}
                  onMouseLeave={() => setHoveredInitiative(null)}>
                  <div
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                      paddingLeft: '8px',
                    }}>
                    {initiative.name.length > 38 ? `${initiative.name.slice(0, 38)}...` : initiative.name}
                  </div>
                </FeatureCard>
                {initiative && hoveredInitiative === initiative.id && (
                  <Dialog
                    opened={true}
                    onClose={() => setHoveredProgrammes(false)}
                    style={{ width: '276px', border: '1px solid #EEE7EA', position: 'relative' }}
                    radius={8}>
                    <Text c="dimmed" size="12px" mb={15}>
                      ID • {initiative.id}
                    </Text>
                    <Text mb={10} pr={30} fw={500}>
                      {initiative.name}
                    </Text>

                    <Divider m="12px -12px" />

                    <Text c="dimmed" size="12px" mb={10}>
                      {initiative.isPortfolioState && initiative.portfolioValueStreams.length > 1
                        ? 'Value streams'
                        : 'Value stream'}
                    </Text>

                    {initiative.isPortfolioState &&
                      valueStreams.length > 0 &&
                      (() => {
                        const defaultValueStream = { id: 'default', name: 'Portfolio Initiative' };

                        const valueStreamsToDisplay = [defaultValueStream, ...initiative.portfolioValueStreams];

                        return valueStreamsToDisplay.map((valueStreamId, index) => {
                          const valueStream =
                            valueStreams.find((stream) => stream.id === valueStreamId.id) || valueStreamId;

                          return (
                            <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'} key={valueStream?.id || index}>
                              <Group preventGrowOverflow={false} wrap="nowrap" m={4}>
                                <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                                  <Group>
                                    <Text size="sm">{valueStream.name}</Text>
                                  </Group>
                                </Group>
                              </Group>
                            </Paper>
                          );
                        });
                      })()}

                    {!initiative.isPortfolioState && (
                      <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'} display={'flex'} key={index}>
                        <Text size="sm" style={{ marginLeft: '10px', alignContent: 'center' }}>
                          {valueStreams.find((stream) => stream.id === initiative.valueStream)?.name}
                        </Text>
                      </Paper>
                    )}

                    <Divider m="12px -12px" />

                    <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
                      <Group preventGrowOverflow={false} wrap="nowrap" m={4}>
                        <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                          <Group>
                            <SvgDate />

                            <Text c="dimmed" size="sm">
                              {toDisplayDate(initiative.plannedRealisationDate)}
                            </Text>
                          </Group>
                          <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}></Text>
                        </Group>
                      </Group>
                    </Paper>

                    <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
                      <Group preventGrowOverflow={false} wrap="nowrap" m={4}>
                        <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                          <Group>
                            <SvgFeatureIcon />

                            <Text c="dimmed" size="sm">
                              Features:{' '}
                              <span>
                                {initiative.features.nodes.length} • {statusCount['Done']}/
                                {initiative.features.nodes.length} done
                              </span>
                            </Text>
                          </Group>
                          <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}></Text>
                        </Group>
                      </Group>
                    </Paper>
                    {initiative.features.nodes.length > 0 && (
                      <LinearWithValueLabel data-value={progress} value={progress.toFixed()} minWidth={0} />
                    )}
                  </Dialog>
                )}
              </>
            );
          })}
          {initiativesForState.length > 3 && (
            <FeatureCard
              onClick={() => toggleShowAllFeaturesForStage(valueStream.id, portfolioState.name)}
              style={{
                backgroundColor: theme.palette.mode === 'dark' ? color.card : '#EFEFEF',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '0px',
                height: '34px',
              }}>
              <div
                style={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  fontSize: '12px',
                  alignSelf: 'center',
                }}>
                {showAllInitiatives?.[valueStream.id]?.[portfolioState.name]
                  ? 'Hide initiatives'
                  : 'Show all initiatives'}
              </div>
            </FeatureCard>
          )}
        </TableCell>
      );
    });

    const totalInitiatives = allInitiatives.filter((initiative) =>
      valueStream.id === 0 ? initiative.isPortfolioState : initiative.valueStream === valueStream.id,
    );

    return (
      <TableRow key={valueStream.id} className={classes.row} style={{ height: 'auto', backgroundColor: '#F7F7F8' }}>
        <TableCell
          className={classes.tableHeadCol}
          onClick={() => handleValueStreamClick(valueStream)}
          style={{
            minWidth: '220px',
            maxWidth: '220px',
            height: 'auto',
            borderRight: `1px solid  ${theme.palette.color.paleGrey2}`,
            borderBottom: `1px solid  ${theme.palette.color.paleGrey2}`,
            position: 'relative',
            cursor: 'pointer',
          }}>
          <span className={classes.teamName} style={{ marginLeft: '0px' }}>
            {valueStream.name}
          </span>

          <Text size="sm" c={theme.palette.text.secondary} mt={10}>
            Initiatives: {totalInitiatives.length}
          </Text>

          {valueStream.programmes && (
            <Text
              size="sm"
              c={theme.palette.text.secondary}
              style={{ cursor: 'pointer', display: 'inline-block' }}
              mt={10}
              onMouseEnter={() => setHoveredProgrammes(valueStream.id)}
              onMouseLeave={() => setHoveredProgrammes(null)}>
              Programmes: {valueStream.programmes.length}
            </Text>
          )}
        </TableCell>
        {hoveredProgrammes === valueStream.id && (
          <Dialog
            opened={true}
            onClose={() => setHoveredProgrammes(false)}
            style={{ width: '276px', border: '1px solid #EEE7EA', position: 'relative' }}
            radius={8}>
            <Text mb={10} pr={30}>
              {valueStream.name}
            </Text>
            <Text c="dimmed" size="12px" mb={15}>
              Programmes
            </Text>

            {valueStream.programmes.map((programme, index) => {
              return (
                <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'} display={'flex'} key={index}>
                  <Text size="sm" style={{ marginLeft: '10px', fontWeight: '700', alignContent: 'center' }}>
                    {programme.name}
                  </Text>
                </Paper>
              );
            })}
          </Dialog>
        )}
        {plannedInitiative}
      </TableRow>
    );
  };

  const onLeave = ({ currentPosition, previousPosition }) => {
    const isAbove = currentPosition === Waypoint.above;
    const wasInside = previousPosition === Waypoint.inside;

    if (isAbove && wasInside) {
      stickyHeaderVar(true);
    }
  };

  const onEnter = () => {
    stickyHeaderVar(false);
  };

  return (
    <Table className={classes.table} style={{ tableLayout: 'inherit' }} stickyHeader={stickyHeader}>
      <TableHead>
        <Waypoint onLeave={onLeave} onEnter={onEnter} />
        <TableRow>
          <TableCell
            key="header"
            className={classes.tableHeadRow}
            style={{ minWidth: '280px', maxWidth: '280px', height: 'auto' }}>
            Value streams
          </TableCell>
          {portfolioStatesConfig.map((state) => (
            <TableCell
              key={state.name}
              className={classes.tableHeadRow}
              style={{
                minWidth: '220px',
                maxWidth: '220px',
                height: 'auto',
                borderBottom: '1px solid  #dfe2e8',
              }}>
              {state.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {valueStreams.map((valueStream) => renderValueStreamDetails(valueStream, portfolioStatesConfig))}
      </TableBody>
    </Table>
  );
};

export default Breakdown;
