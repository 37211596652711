import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { showNotification } from '@mantine/notifications';
import { Event } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { Avatar, Button, Chip, Grid, InputAdornment, MenuItem, Paper, Tooltip, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { find, isEmpty, omitBy, sortBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { BUTTONS, DEV_STATUS, PRIORITY, RAG_STATUS, STAGE, STAGE_VALUE } from 'utils/formConstants';

import FieldsGroup from './NewFieldGroup';
import StoriesFieldGroup from './StoriesFieldGroup';
import Subtasks from './Subtasks';
import AzureDevops from '../../../assets/images/AzureDevops';
import Jira from '../../../assets/images/Jira';
import { GET_ALL_IDEAS, UPDATE_IDEA } from '../../../containers/Form/graphql';
import {
  GET_PORTFOLIO_OBJECTIVES,
  UPDATE_PORTFOLIO_KEY_RESULT_MANY,
} from '../../../containers/Organisation/OKRs/graphql';
import { DELETE_FEATURE, INSERT_FEATURE, UPDATE_FEATURE } from '../../../containers/Product/Features/graphql';
import { validateColumns } from '../../../containers/Product/IdeaBoard/helper';
import { GET_BACKLOGS } from '../../../containers/Settings/GraphQL/backlogs';
import { GET_SUBTASKS } from '../../../containers/Settings/GraphQL/configuration';
import { GET_CONNECTORS } from '../../../containers/Settings/GraphQL/connectors';
import { GET_PROGRAMMES } from '../../../containers/Settings/GraphQL/programmes';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import { GET_USERS } from '../../../containers/Settings/GraphQL/users';
import { GET_PROGRAM_INCREMENTS } from '../../../containers/Settings/ProgramIncrement/graphql';
import { GET_INITIATIVES } from '../../../containers/ValueStream/Initiatives/graphql';
import { GET_MILESTONES } from '../../../containers/ValueStream/Milestones/graphql';
import { GET_PRODUCT_THEMES } from '../../../containers/ValueStream/ProductTheme/graphql';
import {
  drawerLoadingVar,
  resetDrawerDetails,
  selectedProgrammeVar,
  selectedValueStreamVar,
} from '../../../reactiveVariables';
import { openDrawer } from '../../../reactiveVariables';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { hasJiraBE, sortValues } from '../../../utils/helpers';
import { piStatusColor } from '../../../utils/piColors';
import { DATE_FORMAT } from '../../../utils/timeUtils';
import {
  AutoCompleteWrapper,
  Field,
  FormContext,
  renderCheckbox,
  renderDatePicker,
  renderRTE,
  renderSelectField,
  renderTextField,
} from '../../Common/FormFieldsHooks';
import { renderGroupedSelect } from '../../Common/FormFieldsHooks/MantineField';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  name: {
    '& label': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
        display: 'none',
      },
      '&:hover fieldset': {
        borderColor: 'black',
        display: 'inline-flex',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        display: 'inline-flex',
      },
      '&.Mui-error fieldset': {
        borderColor: 'red',
        display: 'inline-flex',
      },
    },
  },
}));

const FeatureDetails = ({ canEdit, element: featureProps = {} }, ref) => {
  const theme = useTheme();

  const FONT_SIZE = 22;
  const DEFAULT_INPUT_WIDTH = 200;

  const { data: { connectors = [] } = {} } = useQuery(GET_CONNECTORS);
  const { data: { backlogs = [] } = {} } = useQuery(GET_BACKLOGS);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);
  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES);
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { users = [] } = {} } = useQuery(GET_USERS);
  const { data: { subtasks = [] } = {} } = useQuery(GET_SUBTASKS);
  const { data: { productThemes = [] } = {} } = useQuery(GET_PRODUCT_THEMES);
  const { data: { ideas = [] } = {} } = useQuery(GET_ALL_IDEAS);
  const { data: { portfolioObjectives = [] } = {} } = useQuery(GET_PORTFOLIO_OBJECTIVES);
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);

  const [insertFeature, { loading: insertLoading }] = useMutation(INSERT_FEATURE);
  const [updateFeature, { loading: updateLoading }] = useMutation(UPDATE_FEATURE);
  const [deleteFeature] = useMutation(DELETE_FEATURE);
  const [updateIdea] = useMutation(UPDATE_IDEA);
  const [updateOkrMany] = useMutation(UPDATE_PORTFOLIO_KEY_RESULT_MANY, {
    refetchQueries: [GET_PORTFOLIO_OBJECTIVES, 'GetPortfolioObjectives'],
  });

  const selectedValueStream = useReactiveVar(selectedValueStreamVar);
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);
  const sprints = programIncrements
    .filter((increment) => increment.programme === selectedProgramme)
    .flatMap((pi) => pi.sprints || []);

  const [firstRender, setFirstRender] = useState(false);

  const programmesInValueStream = programmes.filter((programme) => programme.valueStream.id === selectedValueStream);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openConfirmationTeams, setOpenConfirmationTeams] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const classes = useStyles();
  const drawerLoading = useReactiveVar(drawerLoadingVar);
  const formMethods = useForm({
    shouldUnregister: true,
    defaultValues: (featureProps && {
      ...featureProps,
      businessOwner: featureProps.businessOwner || [],
    }) || {
      stage: 'funnel',
      status: 'To Do',
      ragStatus: 'Green',
      businessOwner: [],
    },
  });
  const {
    formState: {
      dirtyFields: { description, ...restDirty },
      isDirty,
      errors,
    },
    handleSubmit,
    setValue,
    watch,
    control,
  } = formMethods;

  const feature = watch();

  const [inputWidth, setInputWidth] = useState(
    featureProps?.name?.length * FONT_SIZE > DEFAULT_INPUT_WIDTH
      ? (feature?.name?.length + 1) * FONT_SIZE
      : DEFAULT_INPUT_WIDTH,
  );

  const idea = ideas.find((idea) => idea?.next_step_feature_or_initiative_id === featureProps?.id);

  const openThinkTankIdea = () => {
    openDrawer(idea, 'thinkTank');
  };

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      const selectedProgramme = feature.programmeId;
      const selectedTeamId = feature.teamId;

      const selectedTeam = teams.find((team) => team.id === selectedTeamId);

      const programmePartOfTeam = selectedTeam?.programmes?.includes(selectedProgramme);

      if (!programmePartOfTeam && selectedProgramme && selectedTeam) {
        setOpenConfirmationTeams(true);
      } else {
        handleSubmit(onSubmit)();
      }
    },
    handleDelete: () => {
      if (featureProps?.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      !isEmpty(restDirty) && isDirty && canEdit ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
    handleSaveWithDifferentTeam: () => {
      handleSubmit(onSubmit)();
    },
  }));

  useEffect(() => {
    setTimeout(() => setFirstRender(false), 500);
  }, []);

  useEffect(() => {
    const keyResults = portfolioObjectives.reduce((acc, group) => [...acc, ...group.keyResults], []);
    setValue(
      'okrs',
      keyResults.filter((okr) => okr.features.includes(featureProps?.id)),
    );
  }, [portfolioObjectives]);

  const handleDeleteConfirm = () => {
    if (featureProps?.id) {
      deleteFeature({ variables: { id: featureProps.id }, update: deleteUpdate('feature') });
    }
    resetDrawerDetails();
  };

  const onNameChange = (newValue) => {
    if (feature?.name?.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
      setInputWidth((feature?.name?.length + 1) * FONT_SIZE);
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
    setValue('name', newValue, { shouldDirty: true });
  };

  const saveIncrementMetrics = (newIncrementId) => {
    const pi = find(programIncrements, { id: featureProps.programIncrement }) || {};
    if (pi.status === 'planning') return {};

    delete featureProps.completedIncrements?.[newIncrementId];

    return {
      ...featureProps.completedIncrements,
      [featureProps.programIncrement]: {
        ...(featureProps.team.type === 'kanban' && {
          spScope: featureProps.metrics?.storyPoints?.total,
          storiesScope: featureProps.metrics?.storyCount?.total,
        }),
        committedSprint: featureProps.committedSprint,
        originalCommittedSprint: featureProps.originalState?.committedSprint,
      },
    };
  };

  const saveOKRs = (newOkrs, id) => {
    const keyResults = portfolioObjectives.reduce((acc, group) => [...acc, ...group.keyResults], []);
    const oldOKrs = keyResults.filter((okr) => okr.features.includes(featureProps?.id));

    const addedOkrs = newOkrs.filter((okr) => !oldOKrs.some((e) => okr.id === e.id));
    const deletedOkrs = oldOKrs.filter((okr) => !newOkrs.some((e) => okr.id === e.id));

    const add = addedOkrs.map((item, index) => ({
      where: { id: { _eq: item.id } },
      _set: { features: uniq([...item.features, id]) },
    }));

    const remove = deletedOkrs.map((item, index) => ({
      where: { id: { _eq: item.id } },
      _set: { features: item.features.filter((featureId) => featureId !== id) },
    }));

    updateOkrMany({
      variables: { updates: [...add, ...remove] },
    });
  };

  const onSubmit = (values) => {
    const missingFields = validateColumns(values.stage, values);
    const featureToSave = omitBy(values, (property, key) =>
      ['id', 'milestones', 'planned', 'isIdeationBoard', 'order', 'okrs'].includes(key),
    );

    const hasIncrementChanged =
      featureProps && featureProps.programIncrement && featureProps.programIncrement !== values.programIncrement;
    const resetSprints = hasIncrementChanged
      ? { committedSprint: null, estimatedSprint: null, ragStatus: 'Green' }
      : {};
    const completedIncrements =
      hasIncrementChanged && featureProps.metrics
        ? saveIncrementMetrics(values.programIncrement)
        : featureProps?.completedIncrements || {};

    if (featureProps?.id) {
      updateFeature({
        variables: {
          featureId: featureProps.id,
          milestones: values.milestones?.map((milestone) => ({
            featureId: featureProps.id,
            milestoneId: milestone.id,
          })),
          feature: {
            ...featureToSave,
            missingFields,
            ...resetSprints,
            completedIncrements,
            assignedTo: featureToSave.assignedTo ? featureToSave.assignedTo : null,
          },
        },
      }).then(() => {
        resetDrawerDetails();
        showNotification({
          title: 'Feature Updated',
          message: `Feature ${featureProps?.id} was successfully updated`,
        });
      });
      saveOKRs(values.okrs, featureProps?.id);
    } else {
      insertFeature({
        update: insertUpdate('feature'),
        variables: {
          feature: {
            ...featureToSave,
            missingFields: missingFields,
            programmeId: selectedProgramme,
            milestones: isEmpty(values.milestones)
              ? null
              : {
                  data: values.milestones.map((milestone) => ({
                    milestoneId: milestone.id,
                  })),
                },
          },
        },
      }).then((savedFeature) => {
        if (featureProps?.createdFromThinkTank) {
          updateIdea({
            variables: {
              ...featureProps.createdFromThinkTank,
              state: 'Accepted',
              next_step_feature_or_initiative_id: savedFeature.data.feature.returning[0].id,
            },
          });
          saveOKRs(values.okrs, savedFeature.data.feature.returning[0].id);
        }

        resetDrawerDetails();
        showNotification({
          title: 'Feature Created',
          message: `Feature was successfully created`,
        });
      });
    }
  };

  const handleChange = (newValue, field) => {
    const { businessValue, riskReduction, timeCriticality, size } = {
      ...feature,
      [field]: newValue,
    };
    const cod = (businessValue || 0) + (riskReduction || 0) + (timeCriticality || 0);
    const wsjf = Math.round(((cod && size ? cod / size : 0) + Number.EPSILON) * 100) / 100;

    setValue(field, newValue, { shouldDirty: true });
    setValue('costOfDelay', cod);
    setValue('wsjf', wsjf);
  };

  const handleJiraClick = () => {
    const pi = find(programIncrements, { id: feature.programIncrement }) || {};
    const team = find(teams, { id: feature.teamId }) || {};
    const connectorId = team.connectorId || pi.defaultConnectorId;
    const connector = find(connectors, { id: connectorId }) || {};
    const url = hasJiraBE()
      ? connector.apiUrl + '/browse/' + feature.epicId
      : `${connector.apiUrl}/${team?.projectId}/_workitems/edit/${feature.epicId}`;

    window.open(url, '_blank');
  };

  const renderSelectItems = (data) =>
    data.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ));

  const getConnectorId = () => {
    const team = find(teams, { id: feature.teamId }) || {};
    return team.connectorId || pi.defaultConnectorId;
  };

  const { confluenceLink } = feature;
  const pi = find(programIncrements, { id: feature.programIncrement }) || {};
  const team = find(teams, { id: feature.teamId }) || {};

  const showFocusedFields = featureProps?.isIdeationBoard && featureProps?.missingFields;
  const showFieldsForPlannedAndInProgress = ['planned', 'inProgress'].includes(feature?.stage);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContext.Provider value={{ control, errors }}>
        <Grid
          container
          justifyContent="flex-sta"
          alignItems="center"
          spacing={2}
          padding="30px"
          sx={{ paddingTop: '10px', width: '100%', height: '100%' }}>
          <Grid item xs={12} sx={{ marginBottom: '10px', paddingLeft: '2px' }}>
            <Field
              renderField={renderTextField}
              placeHolder="Add Title"
              InputProps={{
                style: { maxWidth: '100%', width: `${inputWidth}px`, fontSize: 22, fontWeight: 600 },
              }}
              autoFocus
              className={classes.name}
              handleChange={(e) => onNameChange(e.target.value)}
              multiline
              title="Title"
              required
              name="name"
              size={12}
            />
          </Grid>
          {featureProps?.id && (
            <Grid container justifyContent="flex-start" item xs={12} spacing={2}>
              <Grid item xs="auto">
                <Chip
                  sx={{
                    backgroundColor: 'color.paper',
                    fontWeight: 'bold',
                    borderRadius: 2,
                  }}
                  label={`ID - ${featureProps.id}`}
                />
              </Grid>
              <Grid item xs="auto">
                <Chip
                  avatar={<Event />}
                  sx={{ backgroundColor: 'color.paper', borderRadius: 2 }}
                  label={`Created: ${DateTime.fromISO(featureProps.createdAt).toFormat(DATE_FORMAT.date)}`}
                />
              </Grid>
              {feature.programIncrement && (
                <Grid item xs="auto">
                  <Chip
                    sx={{
                      color: piStatusColor(pi.status, theme),
                      fontWeight: 'bold',
                      backgroundColor: piStatusColor(pi.status, theme, true),
                      borderRadius: 2,
                    }}
                    label={pi.name}
                  />
                </Grid>
              )}
              {idea && (
                <Grid container item xs={'auto'} alignItems="center">
                  <Chip
                    avatar={<LinkIcon style={{ width: '22px' }} />}
                    onClick={openThinkTankIdea}
                    sx={{ backgroundColor: 'color.paper', borderRadius: 2 }}
                    label={`Idea: ${idea.id}`}
                  />
                </Grid>
              )}
              {feature.assignedTo && (
                <Grid container item xs={6} alignItems="center">
                  <Typography color="textSecondary">Owner:</Typography>
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      fontSize: 10,
                      fontWeight: 'bold',
                      backgroundColor: '#d156a1',
                      marginLeft: '10px',
                      marginRight: '5px',
                    }}>
                    {feature.assignedTo
                      ?.split(' ')
                      .map((n) => n[0])
                      .join('')}
                  </Avatar>
                  <Typography color="textSecondary">{feature.assignedTo}</Typography>
                </Grid>
              )}
            </Grid>
          )}
          <Field
            renderField={renderRTE}
            controls={[
              ['bold', 'italic', 'underline', 'link', 'image'],
              ['unorderedList', 'h1', 'h2', 'h3'],
              ['sup', 'sub'],
              ['alignLeft', 'alignCenter', 'alignRight'],
            ]}
            name="description"
            label="Description"
            multiline
            maxRows="10"
            size={12}
          />
        </Grid>
        <Paper
          elevation={0}
          sx={{
            padding: '10px',
            backgroundColor: theme.palette.color.table,
            height: '100%',
            flexShrink: 0,
          }}>
          <FieldsGroup title="Summary" index={1} defaultExpanded={true}>
            <Field name="programmeId" renderField={renderSelectField} label="Programme" required size={12}>
              {sortValues(programmesInValueStream).map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <Field
              name="teamId"
              renderField={renderSelectField}
              label="Team"
              size={6}
              disabled={feature.programmeId ? false : true}
              focused={showFocusedFields && showFieldsForPlannedAndInProgress && !feature?.teamId}>
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {sortValues(
                teams.filter((team) => team.programmes?.includes(featureProps?.programmeId || selectedProgramme)),
              ).map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <Field
              name="epicId"
              disabled={!!team && !!team.name && !team.board}
              renderField={renderTextField}
              label={hasJiraBE() ? 'JIRA Epic ID' : 'Azure Feature ID'}
              InputProps={
                feature.epicId
                  ? {
                      endAdornment: (
                        <InputAdornment>
                          <a onClick={handleJiraClick} style={{ cursor: 'pointer' }}>
                            <Tooltip title="Open in JIRA">
                              {!hasJiraBE() ? (
                                <AzureDevops color="#0178D5" />
                              ) : (
                                <Jira color={theme.palette.color.buttonIcon} />
                              )}
                            </Tooltip>
                          </a>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
            <Field name="backlogId" renderField={renderSelectField} label="Project / Product">
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {sortValues(backlogs).map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>
            <Field
              name="programIncrement"
              renderField={renderSelectField}
              label="Increment"
              disabled={feature.programmeId ? false : true}>
              <MenuItem key="00" value={null}>
                <em>None</em>
              </MenuItem>
              {renderSelectItems(
                programIncrements.filter((pi) => pi.programme === (feature.programmeId || selectedProgramme)),
              )}
            </Field>
            <Field name="ragStatus" disabled={!team || !!team.board} renderField={renderSelectField} label="Rag Status">
              {Object.entries(RAG_STATUS).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={`${value}`}>
                    {value}
                  </MenuItem>
                );
              })}
            </Field>
            <Field name="status" renderField={renderSelectField} label="Dev Status">
              {Object.entries(DEV_STATUS).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={`${value}`}>
                    {value}
                  </MenuItem>
                );
              })}
            </Field>
            <Field name="stage" renderField={renderSelectField} label="Stage">
              {Object.entries(STAGE).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={STAGE_VALUE[key]}>
                    {value}
                  </MenuItem>
                );
              })}
            </Field>
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Owner"
              name="assignedTo"
              multiple={false}
              control={control}
              options={sortBy(
                users.map((user) => `${user.firstName} ${user.lastName}`),
                'firstName',
              )}
              getOptionLabel={(option) => option.name || option}
            />
            <Field
              name="confluenceLink"
              disabled={!!team && !!team.name && !team.board}
              renderField={renderTextField}
              label="Wiki Link"
              size={12}
              InputProps={
                confluenceLink
                  ? {
                      endAdornment: (
                        <InputAdornment>
                          <a href={confluenceLink} target="_blank" style={{ cursor: 'pointer' }} rel="noreferrer">
                            <Tooltip title="Open In Wiki">
                              <img src={require('images/confluenceIcon.png')} width="24" height="24" />
                            </Tooltip>
                          </a>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Initiative"
              name="initiativeId"
              multiple={false}
              control={control}
              changeSingle={(data) => data?.id || null}
              options={sortValues(initiatives)}
              size={12}
            />
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Product Themes"
              name="productThemes"
              multiple={true}
              nameField="title"
              control={control}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={sortBy(productThemes, 'title')}
              size={12}
            />
          </FieldsGroup>
          <FieldsGroup
            index={2}
            title="Delivery"
            firstRender={firstRender}
            showFocusedFields={
              showFocusedFields && showFieldsForPlannedAndInProgress && (!feature?.teamId || !feature?.committedSprint)
            }>
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Milestones"
              name="milestones"
              multiple={true}
              control={control}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={sortValues(milestones).filter((milestone) => milestone.name)}
              size={12}
            />
            <Field
              name="committedSprint"
              renderField={renderSelectField}
              label="Committed Completion Sprint"
              focused={showFocusedFields && showFieldsForPlannedAndInProgress && !feature?.committedSprint}>
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {renderSelectItems(pi.sprints || [])}
            </Field>
            <Field
              name="estimatedSprint"
              renderField={renderSelectField}
              label="Estimated Completion Sprint"
              disabled={true}>
              <MenuItem key="" value={null}>
                <em>None</em>
              </MenuItem>
              {renderSelectItems(sprints || [])}
            </Field>
            <Field name="plannedStartDate" allowNull={true} renderField={renderDatePicker} label="Planned Start Date" />
            <Field
              name="plannedReleaseDate"
              allowNull={true}
              renderField={renderDatePicker}
              label="Planned Release Date"
            />
            <Field
              name="releaseDate"
              allowNull={true}
              renderField={renderDatePicker}
              label="Actual Release Date"
              size={5}
            />
            <Field name="releaseNumber" renderField={renderTextField} label="Release Number" size={4} />
            <Field name="isDescoped" renderField={renderCheckbox} type="checkbox" label="Deferred" size={3} />
          </FieldsGroup>
          <FieldsGroup
            index={3}
            firstRender={firstRender}
            title="Business Benefits & Prioritisation"
            showFocusedFields={showFocusedFields && feature.stage !== 'funnel' && !feature?.okr}>
            <Field
              errors={errors}
              idField="id"
              label="OKRs"
              name="okrs"
              renderField={renderGroupedSelect}
              changeFunc={(data) => data?.map((option) => ({ id: option.id }))}
              options={portfolioObjectives}
              size={12}
            />
            <Field name="priority" renderField={renderSelectField} label="Priority" size={12}>
              {PRIORITY.map(({ key, value }) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Field>
            <AutoCompleteWrapper
              errors={errors}
              idField="id"
              label="Stakeholders"
              name="businessOwner"
              multiple={true}
              control={control}
              freeSolo={true}
              options={sortBy(
                users.map((user) => `${user.firstName} ${user.lastName}`),
                'firstName',
              )}
              size={12}
            />
            <Field
              name="businessValue"
              handleChange={(e) => handleChange(parseInt(e.target.value), 'businessValue')}
              renderField={renderTextField}
              type="number"
              label="Business Value"
              size={3}
              sx={{ paddingRight: '8px' }}
            />
            +
            <Field
              name="riskReduction"
              handleChange={(e) => handleChange(parseInt(e.target.value), 'riskReduction')}
              renderField={renderTextField}
              type="number"
              label="Risk Reduction"
              size={3}
              sx={{ paddingRight: '8px' }}
            />
            +
            <Field
              name="timeCriticality"
              handleChange={(e) => handleChange(parseInt(e.target.value), 'timeCriticality')}
              renderField={renderTextField}
              label="Time Criticality"
              type="number"
              size={3}
              sx={{ paddingRight: '8px' }}
            />
            =
            <Field
              name="costOfDelay"
              disabled
              renderField={renderTextField}
              label="COD"
              defaultValue=""
              InputLabelProps={{ shrink: featureProps?.costOfDelay != null || feature?.costOfDelay != null }}
              type="number"
              size={2}
            />
            <Field
              name="costOfDelay"
              disabled
              renderField={renderTextField}
              label="COD"
              defaultValue=""
              InputLabelProps={{ shrink: featureProps?.costOfDelay != null || feature?.costOfDelay != null }}
              type="number"
              size={2}
              sx={{ paddingRight: '8px' }}
            />
            ÷
            <Field
              name="size"
              handleChange={(e) => handleChange(parseInt(e.target.value), 'size')}
              renderField={renderTextField}
              label="Size"
              type="number"
              size={2}
              sx={{ paddingRight: '8px' }}
            />
            =
            <Field
              name="wsjf"
              disabled
              renderField={renderTextField}
              defaultValue=""
              InputLabelProps={{ shrink: featureProps?.wsjf != null || feature?.wsjf != null }}
              label="WSJF"
              type="number"
              size={2}
            />
          </FieldsGroup>
          <FieldsGroup
            index={4}
            firstRender={firstRender}
            title="Risks & Acceptance Criteria"
            showFocusedFields={showFocusedFields && feature?.stage !== 'funnel' && !feature?.acceptanceCriterias}>
            <Field
              renderField={renderTextField}
              name="acceptanceCriterias"
              label="Acceptance Criteria"
              multiline
              maxRows="10"
              size={12}
              focused={showFocusedFields && feature?.stage !== 'funnel' && !feature?.acceptanceCriterias}
            />
            <Field
              renderField={renderTextField}
              name="hlDependencies"
              label="High level Dependencies / Risks"
              multiline
              maxRows="10"
              size={12}
            />
          </FieldsGroup>
          <StoriesFieldGroup
            initialStoryCount={featureProps?.metrics?.storyCount?.total}
            feature={feature}
            team={team}
            pi={pi}
            firstRender={firstRender}
            connectorId={getConnectorId()}
            title="Stories"
            classes={classes}
            index={5}
          />
          {!!subtasks.length && (
            <FieldsGroup
              index={6}
              firstRender={firstRender}
              title={`Tasks (${feature.subtasks?.filter((task) => task.done).length || 0}/${
                feature.subtasks?.length || 0
              })`}>
              <Subtasks
                isCreating={!featureProps}
                feature={feature}
                firstRender={firstRender}
                setValue={setValue}
                subtasks={subtasks}
              />
            </FieldsGroup>
          )}
        </Paper>
        <ConfirmationDialog
          open={openConfirmation}
          title={`Delete feature: ${feature.name}`}
          text={`Are you sure you want to delete this feature? If it is linked to a ${
            hasJiraBE() ? 'Jira Epic' : 'Azure Devops Feature'
          }, only the BigAgile copy of the feature will be removed.`}
          handleOk={handleDeleteConfirm}
          handleCancel={() => setOpenConfirmation(false)}
        />
        <ConfirmationDialog
          open={openWarning}
          title="Unsaved changes"
          text="You have unsaved changes. What would you like to do?">
          <Button onClick={() => handleSubmit(onSubmit)()} color="primary">
            {BUTTONS.SAVE}
          </Button>
          <Button onClick={() => resetDrawerDetails()} color="primary">
            {BUTTONS.DISCARD}
          </Button>
          <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
            {BUTTONS.CANCEL}
          </Button>
        </ConfirmationDialog>

        <ConfirmationDialog
          open={openConfirmationTeams}
          title={`Save feature: ${feature.name}`}
          text={
            'There is already a team assigned, are you sure you wish you assign this feature to another programme.'
          }>
          <Button onClick={() => handleSubmit(onSubmit)()} color="primary">
            {BUTTONS.SAVE}
          </Button>
          <Button
            onClick={() => {
              setOpenConfirmationTeams(false);
            }}
            color="primary">
            {BUTTONS.CANCEL}
          </Button>
        </ConfirmationDialog>
        <Backdrop className={classes.backdrop} open={drawerLoading || insertLoading || updateLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </FormContext.Provider>
    </form>
  );
};

export default forwardRef(FeatureDetails);
