import React, { useState } from 'react';

import { useDraggable, useDroppable } from '@dnd-kit/core';
import { useTheme } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { Flipped, Flipper } from 'react-flip-toolkit';

import { DialogPopup } from './DialogPopup';
import { getColumnStyle } from './helper';
import KanbanItem from './kanbanItem';
import Select from '../../../components/Common/Select';
import { useIsFirstRender } from '../../../utils/hooks';

const RenderKanbanItem = ({ feature, index, columnName, isFirst, initiativeLength, showRank, valueStreams }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: feature.id,
    data: { feature, fromColumn: columnName },
  });

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flipped flipId={`${columnName}-${feature.id}`} key={feature.id}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={setNodeRef}
        {...listeners}
        {...attributes}>
        <KanbanItem
          show={!isFirst || index < 10}
          initiativeLength={initiativeLength}
          feature={feature}
          showRank={showRank}
          valueStreams={valueStreams}
        />
        {isHovered && (
          <DialogPopup initiative={feature} index={index} setIsHovered={setIsHovered} valueStreams={valueStreams} />
        )}
      </div>
    </Flipped>
  );
};

const KanbanColumn = ({
  column,
  sortValue,
  onSortChange,
  classes,
  initiativeLength,
  showRank = true,
  allowingSorting = true,
  valueStreams = [],
}) => {
  const theme = useTheme();
  const isFirst = useIsFirstRender();
  const { setNodeRef, isOver } = useDroppable({ id: column.value, data: { column } });

  const sortByOptions = [
    {
      name: 'Name',
      id: 'name',
    },
    {
      name: 'Rank',
      id: 'rank',
    },
  ];

  return (
    <Grid key={column.value} className={classes.column} item>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box className={classes.columnTitle} style={{ flex: 1 }}>
          {column.title.length > 30 ? `${column.title.substring(0, 30)}...` : column.title} ({column.data?.length})
        </Box>

        {allowingSorting && (
          <div style={{ width: '60px' }}>
            <Select
              onChange={(event, value) => onSortChange(column.value, value)}
              placeholder="Rank"
              border={false}
              options={sortByOptions}
            />
          </div>
        )}
      </div>

      <Flipper className={classes.flexColScroll} flipKey={sortValue}>
        <div ref={setNodeRef} style={getColumnStyle({}, isOver, theme)}>
          {column.data.map((initiative, index) => (
            <RenderKanbanItem
              key={initiative.id}
              feature={initiative}
              index={index}
              columnName={column.value}
              initiativeLength={initiativeLength}
              isFirst={isFirst}
              showRank={showRank}
              valueStreams={valueStreams}
            />
          ))}
        </div>
      </Flipper>
    </Grid>
  );
};

export default React.memo(KanbanColumn);
