import { gql } from '@apollo/client';

export const INITIATIVE_FIELDS = gql`
  fragment InitiativeFields on initiatives {
    description
    estimatedCompletionDate
    id
    milestones
    name
    owner
    plannedStartDate
    plannedRealisationDate
    programme
    productThemes
    businessOutcome
    okrs
    teams
    rank
    valueStream
    metrics
    wikiLink
    initiative_state
    initiative_state_type
    portfolio_state
    created_at
    isPortfolioState
    portfolioValueStreams
    features: features_aggregate {
      aggregate {
        count
        sum {
          storyPointCount
        }
        avg {
          percentageDone
        }
      }
      nodes {
        id
        programmeId
        name
        status
        percentageDone
        stage
        epicId
        errors
        teamId
        metrics
        committedSprint
        estimatedSprint
        startingSprint
        plannedReleaseDate
        plannedStartDate
        releaseDate
        storyPointsPerSprint
        ragStatus
        isDescoped
        pi: program_increment {
          id
          programme
        }
      }
    }
  }
`;

export const INSERT_INITIATIVE = gql`
  ${INITIATIVE_FIELDS}
  mutation InsertInitiative($initiative: initiatives_insert_input! = {}) {
    initiative: insert_initiatives_one(object: $initiative) {
      ...InitiativeFields
    }
  }
`;

export const UPDATE_INITIATIVE = gql`
  ${INITIATIVE_FIELDS}
  mutation UpdateInitiative($initiativeId: Int!, $initiative: initiatives_set_input = {}) {
    update_initiatives_by_pk(pk_columns: { id: $initiativeId }, _set: $initiative) {
      ...InitiativeFields
    }
  }
`;

export const DELETE_INITIATIVE = gql`
  mutation DeleteInitiative($id: Int!) {
    initiative: delete_initiatives_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_INITIATIVES = gql`
  ${INITIATIVE_FIELDS}
  query GetInitiatives($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    initiatives: initiatives(
      where: {
        _or: [{ valueStream: { _eq: $valueStream } }, { portfolioValueStreams: { _contains: [{ id: $valueStream }] } }]
      }
      order_by: { id: asc }
    ) {
      ...InitiativeFields
    }
  }
`;

export const UPDATE_INITIATIVE_MANY = gql`
  mutation MyMutation($updates: [initiatives_updates!] = { where: {} }) {
    update_initiatives_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const GET_ALL_INITIATIVES = gql`
  ${INITIATIVE_FIELDS}
  query GetAllInitiatives {
    initiatives: initiatives(order_by: { id: asc }) {
      ...InitiativeFields
    }
  }
`;
