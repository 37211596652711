import { gql } from '@apollo/client';

export const PRODUCT_THEME_FIELD = gql`
  fragment ProductThemeFields on product_themes {
    id
    title
    strategicTheme
    valueStream
  }
`;

export const INSERT_PRODUCT_THEME = gql`
  ${PRODUCT_THEME_FIELD}
  mutation InsertProductTheme($productTheme: [product_themes_insert_input!] = {}) {
    productTheme: insert_product_themes(objects: $productTheme) {
      ...ProductThemeFields
    }
  }
`;

export const UPDATE_PRODUCT_THEME = gql`
  ${PRODUCT_THEME_FIELD}
  mutation UpdateProductTheme($productThemeId: Int!, $productTheme: product_themes_set_input = {}) {
    update_product_themes_by_pk(pk_columns: { id: $productThemeId }, _set: $productTheme) {
      ...ProductThemeFields
    }
  }
`;

export const DELETE_PRODUCT_THEME = gql`
  ${PRODUCT_THEME_FIELD}
  mutation DeleteProductTheme($id: Int!) {
    productTheme: delete_product_themes_by_pk(id: $id) {
      ...ProductThemeFields
    }
  }
`;

export const GET_PRODUCT_THEMES = gql`
  ${PRODUCT_THEME_FIELD}
  query GetProductThemes($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    productThemes: product_themes(where: { valueStream: { _eq: $valueStream } }, order_by: { id: asc }) {
      ...ProductThemeFields
    }
  }
`;

export const GET_ALL_PRODUCT_THEMES = gql`
  ${PRODUCT_THEME_FIELD}
  query GetProductThemes($valueStream: Int) {
    selectedValueStream @client @export(as: "valueStream")
    productThemes: product_themes(order_by: { id: asc }) {
      ...ProductThemeFields
    }
  }
`;
