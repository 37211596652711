import React from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { ActionIcon, Center, Grid, SimpleGrid, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import Initiative from './Initiative';
import classes from './InitiativeDashboard.module.css';
import { getColumTitle, moveDatesLeft, moveDatesRight } from './utils';
import { Dot } from '../../../components/Common/Elements';
import { GET_VALUE_STREAMS } from '../../../containers/Settings/Streams/graphql';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_PROGRAMMES } from '../../Settings/GraphQL/programmes';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';

const TimeLineDashboard = ({
  initiatives,
  columDates,
  setColumnDates,
  projectedFeatures,
  loading,
  dateType,
  expandedByDefault = false,
  view = 'initiatives_timeline',
  selectedValueStream,
}) => {
  const theme = useTheme();
  const { ref, width, height } = useElementSize();
  const atRiskInitiatives = initiatives.filter((initiative) => !initiative.isDone && initiative.ragStatus !== 'green');
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);
  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);

  let featuresAtRisk = '';

  if (initiatives[0]?.features?.nodes?.length) {
    featuresAtRisk = initiatives[0]?.features?.nodes.filter(
      (feature) => feature.status !== 'Done' && feature.ragStatus !== 'green',
    );
  }

  // Helper function to get the name of a value stream by its ID
  const getValueStreamName = (id) => {
    const valueStream = valueStreams.find((vs) => vs.id === id);
    return valueStream ? valueStream.name : '';
  };

  // Sorting logic
  const sortedInitiatives =
    view === 'initiatives_timeline'
      ? initiatives
      : initiatives.sort((a, b) => {
          // if null then all value streams filter is selected
          if (selectedValueStream === null) {
            // Get value stream names for both objects
            const nameA =
              a.isPortfolioState && a.portfolioValueStreams?.length > 0
                ? getValueStreamName(a.portfolioValueStreams[0].id)
                : getValueStreamName(a.valueStream);

            const nameB =
              b.isPortfolioState && b.portfolioValueStreams?.length > 0
                ? getValueStreamName(b.portfolioValueStreams[0].id)
                : getValueStreamName(b.valueStream);

            // Rule 1: Sort alphabetically by value stream name
            if (nameA && nameB) {
              const alphabeticalOrder = nameA.localeCompare(nameB);
              if (alphabeticalOrder !== 0) return alphabeticalOrder;
            }

            // Rule 2: Prioritize items with more portfolioValueStreams within the same value stream group
            // const lengthA = a.portfolioValueStreams ? a.portfolioValueStreams.length : 0;
            // const lengthB = b.portfolioValueStreams ? b.portfolioValueStreams.length : 0;
            // if (nameA === nameB) {
            //   return lengthB - lengthA; // More portfolioValueStreams come first
            // }

            // // Rule 3: Place isPortfolioState with empty portfolioValueStreams last
            const isEmptyPortfolioA = a.isPortfolioState && a.portfolioValueStreams?.length === 0;
            const isEmptyPortfolioB = b.isPortfolioState && b.portfolioValueStreams?.length === 0;

            if (isEmptyPortfolioA && !isEmptyPortfolioB) return 1;
            if (!isEmptyPortfolioA && isEmptyPortfolioB) return -1;

            // // Rule 4: Place objects without any valueStream or portfolioValueStreams at the very end
            if (!nameA && nameB) return 1;
            if (nameA && !nameB) return -1;

            return a.name.localeCompare(b.name);

            // // Rule 5: Default to no further sorting if names are identical
            // return 0;
          } else {
            return a.name.localeCompare(b.name);
          }
        });

  return (
    <>
      <Grid
        gutter={0}
        align={'center'}
        style={{
          margin: 0,
          position: 'sticky',
          top: '-28px',
          zIndex: 100,
          backgroundColor: theme.palette.color.background,
          borderBottom: `1px solid ${theme.palette.color.tableColumn}`,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
        <Grid.Col span={4} h="70px" className={classes.headerCell}>
          <div>
            <Text size="sm" fw={700}>
              {view === 'features_timeline' ? 'Features' : 'Initiatives'}
            </Text>
            <Text size="xs" fw={400} c={theme.palette.text.secondary}>
              {view === 'features_timeline' ? initiatives[0]?.features?.nodes?.length : initiatives.length} Total{' '}
              <Dot
                sx={{
                  marginLeft: '4px',
                  marginRight: '3px',
                  height: '6px',
                  width: '6px',
                  backgroundColor: theme.palette.text.secondary,
                }}
              />{' '}
              {view === 'features_timeline'
                ? featuresAtRisk.length + '/' + initiatives[0]?.features?.nodes?.length
                : atRiskInitiatives.length + '/' + initiatives.length}{' '}
              At Risk
            </Text>
          </div>
        </Grid.Col>
        <Grid.Col align={'center'} ref={ref} span={8} p={0}>
          <SimpleGrid spacing={0} h="70px" m={0} cols={4}>
            {columDates.map((dates, index) => (
              <div
                className={classes.headerCell}
                data-column={true}
                data-last={index === columDates.length - 1}
                key={index}>
                {index === 0 && (
                  <ActionIcon
                    variant={theme.palette.mode === 'dark' ? 'filled' : 'outline'}
                    bg={theme.palette.mode === 'dark' ? theme.palette.color.buttonIcon : theme.palette.color.paper}
                    ml={10}
                    onClick={() => setColumnDates(moveDatesLeft(dateType, columDates))}>
                    <KeyboardArrowLeft />
                  </ActionIcon>
                )}
                <Center h="100%" style={{ flexDirection: 'column', flexGrow: 1 }}>
                  <Text size="sm" align={'center'} fw={700}>
                    {getColumTitle(dateType, dates)}
                  </Text>
                  <Text size="xs" align={'center'} fw={400} c={'dimmed'}>
                    {dates.start.toFormat('dd/LL')} - {dates.end.toFormat('dd/LL')}
                  </Text>
                </Center>
                {index === columDates.length - 1 && (
                  <ActionIcon
                    variant={theme.palette.mode === 'dark' ? 'filled' : 'outline'}
                    bg={theme.palette.mode === 'dark' ? theme.palette.color.buttonIcon : theme.palette.color.paper}
                    mr={10}
                    onClick={() => setColumnDates(moveDatesRight(dateType, columDates))}>
                    <KeyboardArrowRight />
                  </ActionIcon>
                )}
              </div>
            ))}
          </SimpleGrid>
        </Grid.Col>
      </Grid>
      {!!width &&
        !loading &&
        sortedInitiatives.map((initiative, index) => (
          <Initiative
            initiative={initiative}
            key={initiative.id}
            width={width}
            height={height}
            lastChild={index === initiatives.length - 1}
            firstChild={index === 0}
            columnDates={columDates}
            projectedFeatures={projectedFeatures}
            expandedByDefault={expandedByDefault}
            features={features}
            sprints={sprints}
            programmes={programmes}
          />
        ))}
    </>
  );
};

export default TimeLineDashboard;
