import React, { forwardRef } from 'react';

import { useTheme } from '@emotion/react';
import { Chip } from '@mui/material';
import { alpha } from '@mui/material/styles';

const Tag = ({ label, color, sx }, ref) => {
  const theme = useTheme();

  return (
    <Chip
      ref={ref}
      sx={{
        color: color || 'color.buttonIcon',
        backgroundColor: alpha(color || theme.palette.color.buttonIcon, 0.25),
        fontWeight: 'bold',
        borderRadius: 1,
        height: '21px',
        ...sx,
      }}
      label={label}
    />
  );
};

export default forwardRef(Tag);
