import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import {
  closestCenter,
  DndContext,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mantine/hooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { IconGripVertical } from '@tabler/icons-react';

import { mainRef } from '../../../reactiveVariables';
import { orderArrayBy } from '../../../utils/helpers';
import { useInfiniteScroll } from '../../../utils/hooks';
import { FlexDiv } from '../Elements';

export const InnerRow = styled((props) => <Paper elevation={0} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.color.card,
  display: 'flex',
  height: '60px',
  margin: '8px 8px',
  padding: '16px',
  boxShadow: '0 1px 1px 0 rgba(61, 71, 86, 0.05)',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Row = ({ row, fields, expand, id, handleClick, expandFunction, orderedBy, enableDragAndDrop }) => {
  const [open, setOpen] = useState(false);
  const [innerRows, setInnerRows] = useState(null);
  const [loading, setLoading] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        sx={{ height: '52px', position: 'relative' }}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...(orderedBy === 'rank' && enableDragAndDrop ? listeners : {})}>
        {fields.map((field) => (
          <TableCell
            key={field.id}
            onClick={(event) => {
              event.stopPropagation();
              if (field.id !== 'id' || !expand) {
                handleClick(event, row);
              }
            }}
            sx={{ borderBottom: 'none' }}
            style={{
              cursor: field.id !== 'id' || !expand ? 'pointer' : undefined,
              padding: '0px 10px',
              textAlign: field.id === 'id' ? 'center' : 'left',
            }}>
            {orderedBy === 'rank' && field.id === 'rank' && enableDragAndDrop && (
              <IconGripVertical
                style={{ width: '18px', height: '18px', position: 'relative', marginRight: '10px' }}
                stroke={1.5}
              />
            )}
            {row.expand && field.id === 'id' && (
              <>
                <IconButton
                  id="expand"
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                    setInnerRows(expandFunction && expandFunction(row, setLoading));
                  }}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <Backdrop sx={{ position: 'absolute' }} open={loading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
            {row[field.id]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow sx={{ border: 'none' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={fields.length}>
          <Collapse in={open && !loading} timeout="auto">
            <FlexDiv style={{ padding: '0 24px' }}>
              <Box sx={{ margin: '0 8px 0 8px', flexGrow: 1 }}>{innerRows}</Box>
            </FlexDiv>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const EntityTable = ({
  headCells,
  handleRowClick,
  searchQuery,
  rows,
  expandFunction,
  expand = false,
  updateRank,
  enableDragAndDrop = false,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderedBy, setOrderedBy] = useState('rank');
  const [nbRows, setNbRows] = useState(15);
  const ref = useReactiveVar(mainRef);
  const matches = useMediaQuery('(min-width: 1400px)');
  const [currentRows, setCurrentRows] = useState(rows);

  const loadMore = () => {
    setNbRows(nbRows + 10);
    setLoading(false);
  };

  const [loading, setLoading, distanceBottom, hasScroll] = useInfiniteScroll(
    nbRows,
    rows?.length > nbRows,
    loadMore,
    ref,
  );

  // useEffect(() => {
  //   setCurrentRows(rows);
  // }, [rows]);

  useEffect(() => {
    const rowsData = searchQuery
      ? rows.filter((row) => Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchQuery)))
      : rows;

    setCurrentRows(orderArrayBy(rowsData, orderedBy, order));
  }, [rows, searchQuery, orderedBy, order]);

  // const rowsData = searchQuery
  //   ? rows.filter((row) => Object.values(row).some((value) => value?.toString().toLowerCase().includes(searchQuery)))
  //   : rows;

  const handleRequestSort = (event, property) => {
    const isAsc = orderedBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderedBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function getTableCell(name, title, size, sx) {
    return (
      <TableCell sx={sx} key={name} sortDirection={orderedBy === name ? order : false} width={size}>
        <TableSortLabel
          active={orderedBy === name}
          direction={orderedBy === name ? order : 'asc'}
          onClick={createSortHandler(name)}>
          {title}
        </TableSortLabel>
      </TableCell>
    );
  }
  const handleDragEnd = (event) => {
    if (orderedBy === 'rank') {
      const { active, over } = event;

      if (active.id !== over.id) {
        setCurrentRows((items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);
          const newOrder = arrayMove(items, oldIndex, newIndex);
          updateRank(newOrder);
          return newOrder;
        });
      }
    }
  };

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 5 },
  });
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 5 },
  });

  const sensors = useSensors(pointerSensor, mouseSensor);

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      measuring={measuringConfig}
      sensors={sensors}>
      <SortableContext items={currentRows} strategy={verticalListSortingStrategy}>
        <TableContainer sx={{ height: '100%', overflow: 'hidden' }}>
          <Table
            sx={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, overflow: 'hidden' }}
            style={{ tableLayout: 'fixed', width: '100%' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'color.background', borderBottom: '2px solid color.darkPaper' }}>
                {headCells.map((headCell) =>
                  getTableCell(
                    headCell.id,
                    headCell.label,
                    matches || !headCell.smWidth ? headCell.width : headCell.smWidth,
                    {
                      padding: '9px',
                    },
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderArrayBy(currentRows, orderedBy, order)
                .slice(0, nbRows)
                .map((row) => (
                  <Row
                    handleClick={handleRowClick}
                    key={row.id}
                    id={row.id}
                    row={row}
                    orderedBy={orderedBy}
                    enableDragAndDrop={enableDragAndDrop}
                    fields={headCells}
                    expand={expand}
                    expandFunction={expandFunction}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SortableContext>
    </DndContext>
  );
};

export default EntityTable;
