import React, { useMemo, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { SegmentedControl, useMantineColorScheme } from '@mantine/core';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import SectionMenu from 'components/Common/SectionHeader/components/SectionMenu';

import Breakdown from './Breakdown';
import PortfolioKanban from './PortfolioKanban';
import Switch from '../../../components/Common/Switch';
import {
  openDrawer,
  selectedOwnerVar,
  selectedProductThemeVar,
  selectedTeamVar,
  selectedValueStreamVar,
} from '../../../reactiveVariables';
import { useProjectedFeatures } from '../../../utils/hooks';
import { styles } from '../../Product/IdeaBoard/styles';
import { defaultPortfolioStateList } from '../../Settings/Configuration/PortfolioStates/defaultStateList';
import { GET_INITIATIVE_STATE_CONFIG, GET_PORTFOLIO_STATES_CONFIG } from '../../Settings/GraphQL/configuration';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_VALUE_STREAMS } from '../../Settings/Streams/graphql';
import { GET_ALL_INITIATIVES } from '../../ValueStream/Initiatives/graphql';
import TimeLineDashboard from '../../ValueStream/InitiativesDashboard/TimeLineDashboard';
import { getColumnDates } from '../../ValueStream/InitiativesDashboard/utils';
import { initiativeWithDates } from '../../ValueStream/utils';

const useStyles = makeStyles(styles);

export default function OperationalPortfolio() {
  const classes = useStyles();
  const handleCreate = () => openDrawer(null, 'initiative');

  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);
  const { data: { initiatives = [] } = {}, refetch: refetchInitiatives } = useQuery(GET_ALL_INITIATIVES);
  const { data: { sprints = [] } = {}, loading } = useQuery(GET_SPRINTS);
  const { data: { portfolioStates = [] } = {} } = useQuery(GET_PORTFOLIO_STATES_CONFIG);
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);

  const [dateType, setDateType] = useState('quarter');
  const [columDates, setColumnDates] = useState(getColumnDates(dateType));
  const [initiativesToggle, setInitiativesToggle] = useState('allInitiatives');

  const { colorScheme } = useMantineColorScheme();

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const projectedFeatures = useProjectedFeatures();
  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedValueStream = useReactiveVar(selectedValueStreamVar);

  const portfolioStatesConfig = useMemo(
    () => (portfolioStates.length ? portfolioStates[0].value.columns : defaultPortfolioStateList),
    [portfolioStates],
  );

  const activeValueStreams = valueStreams.filter((stream) => stream.isActive === true);

  const TABS = [
    {
      index: 0,
      name: 'Portfolio Kanban',
    },
    {
      index: 1,
      name: 'Breakdown',
    },
    {
      index: 2,
      name: 'Timeline',
    },
  ];

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const refetchListOfInitiatives = () => {
    refetchInitiatives();
  };

  const mapInitiativeState = (initiative) => ({
    ...initiative,
    initiative_state: initiative.initiative_state || 'Unknown state',
  });

  const filterByProductTheme = (initiative, selectedProductTheme) => {
    return !selectedProductTheme || initiative.productThemes.some((theme) => theme.id === selectedProductTheme);
  };

  const filterByTeams = (initiative, selectedTeams) => {
    return (
      selectedTeams.length === 0 ||
      (Array.isArray(initiative.teams) && initiative.teams.some((team) => selectedTeams.includes(team.id)))
    );
  };

  const filterByOwner = (initiative, selectedOwners) => {
    return selectedOwners.includes(initiative.owner);
  };

  const filterByValueStream = (initiative, selectedValueStream) => {
    return (
      !selectedValueStream ||
      initiative.valueStream === selectedValueStream ||
      initiative.portfolioValueStreams?.some((portfolioValueStream) => portfolioValueStream.id === selectedValueStream)
    );
  };

  const initiativesWithDatesAndRag = useMemo(() => {
    const mappedInitiatives = initiatives.map(mapInitiativeState);

    const filteredInitiatives = mappedInitiatives.filter((initiative) => {
      const productThemeMatches = filterByProductTheme(initiative, selectedProductTheme);
      const teamMatches = filterByTeams(initiative, selectedTeams);
      const valueStreamFilter = filterByValueStream(initiative, selectedValueStream);

      return productThemeMatches && teamMatches && valueStreamFilter;
    });

    const initiativesWithDates = filteredInitiatives.map((initiative) =>
      initiativeWithDates(initiative, sprints, projectedFeatures),
    );

    if (selectedOwners.length) {
      return initiativesWithDates.filter((initiative) => filterByOwner(initiative, selectedOwners));
    }

    return initiativesWithDates;
  }, [
    selectedProductTheme,
    selectedTeams,
    selectedOwners,
    initiatives,
    sprints,
    projectedFeatures,
    selectedValueStream,
  ]);

  const portfolioStateInitatives = initiativesWithDatesAndRag
    .filter((initiative) => initiative.isPortfolioState === true)
    .sort((a, b) => a?.name.localeCompare(b.name));

  const TOGGLE_DONE = [
    { value: 'allInitiatives', label: 'All Initiatives' },
    { value: 'excludeDone', label: 'Exclude Done' },
  ];

  return (
    <div className={classes.root}>
      <Toolbar className={classes.appBar} disableGutters>
        <div className={classes.toolbarLeft}>
          <Typography fontSize={20} fontWeight={600}>
            Operational Portfolio
          </Typography>
          <IconButton
            key="add"
            aria-label="Add"
            color="inherit"
            onClick={handleCreate}
            size="small"
            sx={{ marginLeft: '6px' }}>
            <AddBoxIcon />
          </IconButton>
        </div>
      </Toolbar>

      <SectionMenu handleChange={handleTabChange} value={value} tabs={TABS} />
      <section
        style={{
          marginTop: '20px',
          overflowY: value === 2 ? 'scroll' : 'auto',
          paddingTop: value === 2 ? '28px' : '0px',
        }}>
        {value === 0 && (
          <PortfolioKanban
            portfolioStatesConfig={portfolioStatesConfig}
            initiatives={portfolioStateInitatives}
            valueStreams={activeValueStreams}
            initiativeStates={initiativeStates}
            refetchListOfInitiatives={refetchListOfInitiatives}
          />
        )}
        {value === 1 && (
          <Breakdown
            allInitiatives={portfolioStateInitatives}
            valueStreams={activeValueStreams}
            portfolioStatesConfig={portfolioStatesConfig}
          />
        )}
        {value === 2 && (
          <>
            <Switch
              value={dateType}
              onChange={(value) => setDateType(value)}
              data={[
                { label: 'Month', value: 'month' },
                { label: 'Quarter', value: 'quarter' },
                { label: 'Year', value: 'year' },
              ]}
            />

            <SegmentedControl
              style={{ marginLeft: '10px' }}
              value={initiativesToggle}
              onChange={(value) => setInitiativesToggle(value)}
              data={TOGGLE_DONE}
              mb={10}
            />

            <TimeLineDashboard
              initiatives={
                initiativesToggle === 'allInitiatives'
                  ? portfolioStateInitatives
                  : portfolioStateInitatives.filter((initiative) => initiative.portfolio_state !== 'Done')
              }
              projectedFeatures={projectedFeatures}
              columDates={columDates}
              loading={loading}
              setColumnDates={setColumnDates}
              dateType={dateType}
            />
          </>
        )}
      </section>
    </div>
  );
}
