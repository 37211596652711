import React, { forwardRef } from 'react';

import { useMutation } from '@apollo/client';

import {
  DELETE_CONFIGURATION,
  GET_SUBTASKS,
  INSERT_CONFIGURATION,
  UPDATE_CONFIGURATION,
} from '../../../containers/Settings/GraphQL/configuration';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { removeIdField } from '../../../utils/helpers';
import ElementDetails from '../../Common/ElementDetail';
import { Field, renderCheckbox, renderTextField } from '../../Common/FormFieldsHooks';

const SubtaskDetails = ({ element, canEdit }, ref) => {
  const [insertSubtask] = useMutation(INSERT_CONFIGURATION);
  const [updateSubtask] = useMutation(UPDATE_CONFIGURATION);
  const [deleteSubtask] = useMutation(DELETE_CONFIGURATION);

  const onDelete = () => {
    deleteSubtask({
      variables: { id: element.id },
      refetchQueries: [GET_SUBTASKS, 'GetSubtaks'],
    }).then(() => resetDrawerDetails());
  };

  const onSubmit = (values) => {
    const subtaskToSave = removeIdField(values);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    values.id
      ? updateSubtask({
          variables: { configuration: { value: subtaskToSave }, configurationId: values.id },
          refetchQueries: [GET_SUBTASKS, 'GetSubtaks'],
        }).then(() => resetDrawerDetails())
      : insertSubtask({
          variables: { configuration: { type: 'subtask', value: subtaskToSave } },
          refetchQueries: [GET_SUBTASKS, 'GetSubtaks'],
        }).then(() => resetDrawerDetails());
  };

  return (
    <ElementDetails
      ref={ref}
      element={element}
      defaultValues={element}
      canEdit={canEdit}
      submitFunc={onSubmit}
      deleteFunc={onDelete}
      title="Subtask">
      <Field name="name" renderField={renderTextField} label="Name" size={12} required />
      <Field name="isDefault" renderField={renderCheckbox} label="Enabled by Default" size={12} />
    </ElementDetails>
  );
};

export default forwardRef(SubtaskDetails);
