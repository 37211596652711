import React from 'react';

import {
  Dialog,
  Group,
  Paper,
  Text,
  useMantineTheme,
  Alert,
  rgba,
  Stack,
  Badge,
  Divider,
  ThemeIcon,
} from '@mantine/core';
import { values } from 'lodash';

import Copy from '../../../assets/images/Copy';
import Heart from '../../../assets/images/Heart';
import SvgHexagone from '../../../assets/images/Hexagon';
import RightArrow from '../../../assets/images/RightArrow';
import Tick from '../../../assets/images/Tick';
import Todo from '../../../assets/images/Todo';
import SvgWarning from '../../../assets/images/Warning';
import LinearWithValueLabel from '../../../containers/ValueStream/components/OKRTable/LinearWithValueLabel';
import { getStatusColorMantine } from '../../../utils/piColors';
export const DialogFeaturePopup = ({ feature, setIsFeatureHovered, teams }) => {
  const theme = useMantineTheme();
  const color = getStatusColorMantine(feature || {}, theme);

  return (
    <Dialog
      opened={true}
      title="Detailed Information"
      style={{ width: '276px', border: '1px solid #EEE7EA', position: 'relative' }}
      withBorder
      size="lg"
      onClose={() => setIsFeatureHovered(false)}
      onMouseEnter={() => setIsFeatureHovered(feature.id)}
      onMouseLeave={() => setIsFeatureHovered(null)}
      radius="md">
      <Text c="dimmed" size="12px" mb={15}>
        ID • {feature.id}
      </Text>
      <Text size="sm" mb="xs" fw={500}>
        {feature.name}
      </Text>
      <Divider m="12px -12px" />

      <Text size="sm" mb="xs" fw={500}>
        Status: <span style={{ fontWeight: '100' }}>{feature.status}</span>
      </Text>
      {feature.epicId && (
        <Group justify="space-between" grow mb="xs">
          <Text size="sm" fw={500}>
            Epic ID: <span style={{ fontWeight: '100' }}>{feature.epicId}</span>
          </Text>
        </Group>
      )}
      <Divider m="12px -12px" />

      {feature?.metrics && (
        <>
          <Text c="dimmed" size="12px" mb={10} mt={10}>
            Story count
          </Text>

          <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
            <Group preventGrowOverflow={false} wrap="nowrap" m={4}>
              <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                <Group>
                  <Copy style={{ marginRight: '-10px' }} />

                  <Text size="sm">Total</Text>
                </Group>
                <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
                  {feature?.metrics?.storyCount?.total}
                </Text>
              </Group>
            </Group>
          </Paper>

          <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
            <Group preventGrowOverflow={false} wrap="nowrap" m={4}>
              <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                <Group>
                  <Todo style={{ marginRight: '-11px' }} />

                  <Text size="sm">To do</Text>
                </Group>
                <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
                  {feature?.metrics?.storyCount?.toDo}
                </Text>
              </Group>
            </Group>
          </Paper>

          <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
            <Group preventGrowOverflow={false} wrap="nowrap" m={4}>
              <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                <Group>
                  <RightArrow style={{ marginRight: '-11px' }} />

                  <Text size="sm">In progress</Text>
                </Group>
                <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
                  {feature?.metrics?.storyCount?.inProgress}
                </Text>
              </Group>
            </Group>
          </Paper>

          <Paper mb={4} withBorder shadow="none" radius={8} h={'34px'}>
            <Group preventGrowOverflow={false} wrap="nowrap" m={4}>
              <Group position="apart" style={{ width: '100%', height: '24px', marginLeft: '5px' }}>
                <Group>
                  <Tick style={{ marginRight: '-7px' }} />

                  <Text size="sm">Done</Text>
                </Group>
                <Text size="sm" style={{ marginLeft: 'auto', marginRight: '5px', fontWeight: '700' }}>
                  {feature?.metrics?.storyCount?.done}
                </Text>
              </Group>
            </Group>
          </Paper>
        </>
      )}

      <div style={{ margin: '10px 0' }}>
        <LinearWithValueLabel color={color} value={feature?.metrics?.percentageDoneStories || 0} />
      </div>

      {feature.errors &&
        values(feature.errors).map((error, index, array) => (
          <Alert
            icon={<SvgWarning />}
            color={theme.other.colours.red}
            p={4}
            mb={index === array.length - 1 ? 'xs' : 4}
            title={error}
            key={index}
          />
        ))}

      {feature.metrics?.teams?.length > 1 && (
        <Paper p="xs" mb={4} withBorder shadow="none">
          <Group m={4}>
            <Badge radius="5px" p={4} color="grey">
              {feature?.metrics?.teams?.length}
            </Badge>
            <Text>Teams</Text>
          </Group>
          <Divider ml={4} mr={4} mt={8} mb={8} />
          {feature?.metrics?.teams.map((teamData, index) => (
            <Group preventGrowOverflow={false} wrap="nowrap" m={4} key={index}>
              <ThemeIcon
                variant="outline"
                style={{ backgroundColor: rgba(theme.other.colours.purplePink, 0.15) }}
                color={theme.other.colours.purplePink}>
                <SvgHexagone />
              </ThemeIcon>
              <Stack gap={0}>
                <Text lineClamp={1}>{(teams.find((team) => team.id == teamData.teamId) || {}).name}</Text>
                <Text c="dimmed">{teamData.issuesCount} Stories Planned</Text>
              </Stack>
            </Group>
          ))}
        </Paper>
      )}
    </Dialog>
  );
};
