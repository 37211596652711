import React from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { ActionIcon, Divider, Grid, Group, Text, Tooltip, Badge } from '@mantine/core';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

import { CenteredCol } from './InitiativeDashboard.style';
import SvgDashboard from '../../../assets/images/Dashboard';
import Tag from '../../../components/Common/Tag';
import { GET_VALUE_STREAMS } from '../../../containers/Settings/Streams/graphql';
import { openDrawer } from '../../../reactiveVariables';
import { color, darkColors } from '../../../shared/styles/color';
import { truncate } from '../../../utils/stringUtils';
import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';

const InitiativeTitle = ({ onExpand, expanded, initiative, color: initiativeColor }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const features = initiative.features?.nodes || [];

  const handleOpenDashboard = (event) => {
    event.stopPropagation();
    navigate(`/valueStream/initiative/${initiative.id}`, { state: { from: '/valueStream' } });
  };

  const isInitiativeView = location.pathname.includes('/valueStream/initiative/');

  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);

  const assignedStreams = valueStreams
    .filter((stream) =>
      initiative.isPortfolioState && initiative.portfolioValueStreams.length > 0
        ? initiative.portfolioValueStreams.some((vs) => vs.id === stream.id)
        : stream.id === initiative.valueStream && stream.isActive,
    )
    .sort((a, b) => {
      if (initiative.isPortfolioState && initiative.portfolioValueStreams.length > 0) {
        const orderA = initiative.portfolioValueStreams.findIndex((vs) => vs.id === a.id);
        const orderB = initiative.portfolioValueStreams.findIndex((vs) => vs.id === b.id);
        return orderA - orderB;
      }
      return 0;
    });

  const isOrgPage =
    window.location.pathname === '/organisation/dashboard' || window.location.pathname === '/organisation/portfolio';

  // Only count value streams that are different from the primary one (because of portfolio)
  const additionalValueStreams =
    initiative.portfolioValueStreams?.length > 1 ? initiative.portfolioValueStreams?.length - 1 : 0;

  return (
    <div style={{ display: 'flex', height: '68px', alignItems: 'center', paddingRight: '8px' }}>
      <Grid gutter={0} w={'100%'} style={{ margin: 0 }}>
        <CenteredCol p={0} span="content" style={{ width: '10px' }}>
          <Divider size="lg" style={{ height: '100%', radius: '5px' }} orientation="vertical" color={initiativeColor} />
        </CenteredCol>
        <CenteredCol p={0} span="content" style={{ width: '30px', marginTop: '1px' }}>
          {!!initiative.features?.nodes?.length && (
            <ActionIcon color="darkGrey.7" size="xs" variant="filled" onClick={() => onExpand(!expanded)}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </ActionIcon>
          )}
        </CenteredCol>
        <CenteredCol p={0} span="auto" maw="100vh">
          <CenteredCol p={0} span="auto" maw="100vh" align="start" style={{ display: 'flex', height: '25px' }}>
            {!isInitiativeView && !!initiative.features?.nodes?.length && (
              <Tooltip label="Open Initiative View" withArrow transitionProps={{ transition: 'pop', duration: 200 }}>
                <ActionIcon
                  variant="transparent"
                  color="var(--mantine-color-buttonIcon)"
                  onClick={handleOpenDashboard}
                  styles={{ icon: { alignItems: 'flex-start', justifyContent: 'left' } }}>
                  <SvgDashboard />
                </ActionIcon>
              </Tooltip>
            )}

            <Tooltip
              width={'500px'}
              zIndex={1000}
              color={theme.palette.mode === 'dark' ? 'white' : darkColors.card}
              label={
                <div>
                  <strong style={{ color: theme.palette.mode === 'dark' ? 'white' : darkColors.card }}>
                    Progress:
                  </strong>
                  <LinearWithValueLabel
                    data-value={initiative.features?.aggregate?.avg?.percentageDone || 0}
                    value={initiative.features?.aggregate?.avg?.percentageDone?.toFixed() || 0}
                  />

                  {assignedStreams && (
                    <>
                      <div style={{ marginTop: '10px' }}>
                        <strong style={{ color: theme.palette.mode === 'dark' ? 'white' : darkColors.card }}>
                          Value Streams:
                        </strong>
                      </div>
                      {assignedStreams.map((stream) => (
                        <div
                          key={stream.id}
                          style={{ color: theme.palette.mode === 'dark' ? 'white' : darkColors.card }}>
                          {stream.name}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              }
              multiline
              styles={{
                tooltip: {
                  width: 300,
                  border: `1px solid ${darkColors.tileBorder}`,
                  borderRadius: '8px',
                  padding: '10px',
                  background: theme.palette.mode === 'dark' ? darkColors.card : 'white',
                },
              }}
              transitionProps={{ transition: 'pop', duration: 200 }}>
              <Text
                size="sm"
                fw={700}
                lineClamp={1}
                onClick={() => openDrawer(initiative, 'initiative')}
                style={{ cursor: 'pointer' }}>
                {initiative.name}
              </Text>
            </Tooltip>
            {(initiative.isPortfolioState || initiative.initiative_state) && (
              <Group
                mr={10}
                gap={8}
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <Tag
                  color="#c4c4c4"
                  label={initiative.isPortfolioState ? initiative.portfolio_state : initiative.initiative_state}
                  sx={{ width: '102px', color: '#808080' }}
                />
              </Group>
            )}
          </CenteredCol>
          <CenteredCol p={0} justify="space-between" style={{ display: 'flex', paddingLeft: '32px' }}>
            {isOrgPage && assignedStreams[0] && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                <Badge
                  color={color.purpulishPink}
                  size="sm"
                  radius="4px"
                  sx={{
                    padding: '4px',
                    color: 'white',
                    backgroundColor: '#59585C',
                  }}>
                  {truncate(assignedStreams[0]?.name, 17)}
                  {additionalValueStreams > 0 ? ` +${additionalValueStreams}` : ''}
                </Badge>
              </div>
            )}

            <Group
              mr={10}
              gap={8}
              style={{
                marginLeft: 'auto',
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <Tag label={`Features: ${features.length}`} sx={{ width: '102px' }} />
            </Group>
          </CenteredCol>
        </CenteredCol>
      </Grid>
    </div>
  );
};

export default InitiativeTitle;
